import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import { BASEURL } from "../configure";
import complicated from "../images/complicateddesktop.png";
import mobrevol from "../images/revolutionary.png";
import SliderCardOurProjectMob from "./SliderCardOurProjectMob";
import SliderCard from "./SliderCards";
const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  display: none;

  // @media screen and (max-width: 899px) {
  //   padding: 150px 0;
  // }
  @media screen and (max-width: 899px) {
    display: flex;
    padding: 20px 0 100px 0;
  }
`;
const Index = () => {
  const [ getProjectData, setProjectData ] = useState('')
  const settings = {
    dots: false,
    slidesToShow: 2,
    swipeToSlide: true,
    autoplay: true,
    infinite: true,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  let getPrjects = async () => {
    let projects = await fetch(BASEURL+'project?_embed', {
      method: 'GET',
    })
    const data = await projects.json();
    
  setProjectData(data.map((key, index) => {
      return  (
        <div>
          <SliderCardOurProjectMob image={key?._embedded?.['wp:featuredmedia'][0]['source_url']} />
        </div>
      )
    }))
  }
  useEffect(() => {
    getPrjects();
  }, []);

  return (
    <div>
      <SectionWrapper p="120px 0">
        <Container maxWidth="xl" id="team">
          <Grid container justifyContent="center" alignContent="center">
            <Grid xs={12}>
              <div data-aos="fade-zoom-in" data-aos-easing="ease-in" data-aos-duration="900" data-aos-delay="900" data-aos-offset="0"  >
                <Slider {...settings}>
                  {getProjectData}
                </Slider>
              </div>
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Index;
