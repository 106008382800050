import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { P, SectionSmallHeading, SectionSubHeading } from "../../../styles";

import Slider from "react-slick";
import SliderCard from "../../../components/SliderCardsForProject";
import buckley from "../../../images/revolutionary.png";
import patrick from "../../../images/complicateddesktop.png";
import SliderForMobile from "../../../components/SliderForMobile";
import tearup from "../../../images/tearup.png";
import projectbg from "../../../images/projectbg.png";
import teardown from "../../../images/teardown.png";
import { BASEURL } from "../../../configure";

const SectionWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  /* align-items: center; */
  padding: 0;
  background: url(${tearup}), url(${projectbg});
  background-position: top, center;
  background-repeat: no-repeat;
  background-size: contain, cover;
  @media screen and (max-width: 1399px) {
    background-size: contain, auto;
  }
  @media screen and (max-width: 899px) {
    display: flex;
    min-height: 100vh;
    background: url(${tearup}), url(${teardown}), url(${projectbg});
    background-position: top, bottom, center;
    background-size: contain, contain, auto;
    padding: 10px 0 10px 0;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: 599px) {
    min-height: 84vh;
    padding: 0px 0 0px 0;
  }
  @media screen and (max-width: 429px) {
    min-height: 74vh;
  }
`;
const MobSlider = styled.div`
  display: none;
  @media screen and (max-width: 899px) {
    display: flex;
    padding: 40px 0;
    justify-content: center;
  }
`;
const TearDownRelative = styled.div`
  position: relative;
`;
const TearDown = styled.div`
  width: 100%;
  position: absolute;
  bottom: -10px;
  z-index: 1;
  @media screen and (max-width: 899px) {
    display: none;
  }
`;

const SlideingCards = styled.div`
  @media screen and (max-width: 899px) {
    display: none;
  }
`;
const GridsHEadingPAraDesktop = styled(Grid)`
  padding: 0;
  @media screen and (max-width: 1299px) {
    // padding: 50px 0;
  }
`;
const SliderWrapper = styled.div`
  height: calc(100vh - 557px);
  padding: 15px;
  height: 530px;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 0px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #b80e0e;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #0afff3;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const DIv = styled.div`
  padding: 150px 0;
`;
const Index = () => {
  const sliders = useRef(null);
  const [ getProjectData, setProjectData ] = useState('')
  function scroll(e) {
    if (sliders.current === null) return 0;

    e.clientY > -181
      ? sliders.current.slickNext()
      : sliders.current.slickPrev();
  }
  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "330px 0px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    vertical: true,
    verticalSwiping: true,
    adaptiveHeight: true,
    variableHeight: true,
    swipeToSlide: true,
    autoplaySpeed: 5000,
    draggable: true,
    speed: 700,
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let getPrjects = async () => {
    let projects = await fetch(BASEURL+'project?_embed', {
      method: 'GET',
    })
    const data = await projects.json();
    
    setProjectData(data.map((key, index) => {
      return  (
        <SliderCard
          key={index}
          image={key?._embedded?.['wp:featuredmedia'][0]['source_url']}
          title={key['title']['rendered']}
          tagLine={key['content']['rendered']}
          spotifylink={key['metadata']?.spotify? key['metadata']?.spotify : '#'}
          applemusiclink={key['metadata']?.applemusic? key['metadata']?.applemusic : '#'}
        />
      )
    }))
  }
  useEffect(() => {
    getPrjects();

    window.addEventListener("wheel", scroll, false);

    return () => {
      window.removeEventListener("wheel", scroll, false);
    };
  }, []);
  return (
    <div>
      <SectionWrapper>
        <Container maxWidth="xl" id="ourproject">
          <GridsHEadingPAraDesktop container alignItems="center">
            <Grid xs={12} md={6}>
              <SectionSubHeading
                p="180px 0 30px 0"
                p599="70px 0 0 0"
                p1270="150px 0 30px 0"
                fs1270="55px"
                fs599="55px"
                align599="center"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="300"
                data-aos-offset="0"
                data-aos-duration="700"
              >
                Our Projects
              </SectionSubHeading>
            </Grid>
          </GridsHEadingPAraDesktop>
          <SliderForMobile />
          <SlideingCards>
            <SliderWrapper className="SliderWrapper">
              {getProjectData}
              <DIv></DIv>
            </SliderWrapper>
          </SlideingCards>
        </Container>
      </SectionWrapper>
      <TearDownRelative>
        <TearDown>
          <img
            src={teardown}
            style={{ width: "100%", height: "auto" }}
            layout="intrinsic"
          />
        </TearDown>
      </TearDownRelative>
    </div>
  );
};

export default Index;
