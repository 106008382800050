import React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";

import styled from "@emotion/styled";
import { Toolbar } from "@mui/material";

const Items = styled.div`
  padding-top: 10px;
  // opacity: 0.8;
  font-size: 12px !important;
  font-family: PoppinsLight;
  color: black;
`;
const MenuLinks = styled.a`
  background-color: #fff !important;
  text-decoration: none;
`;
const MenusLinks = styled.div`
  padding: 0 !important;
  margin: 0 !important;
  /* background-color: #ff1010 !important; */
  text-decoration: none !important;
  border-radius: "20px";
`;
const Testing = styled(MenuItem)`
  /* background-color: #eb1c1c !important; */
  text-decoration: none;
`;
const Index = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar
      position="static"
      style={{ color: "white", backgroundColor: "transparent", boxShadow: "none", padding: "20px 0px 12px 0", zIndex: "9999999999999999999999", }} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div maxWidth="xl"
            style={{ display: "flex", justifyContent: "space-between", float: "left",}}>
            <Box
              sx={{flexGrow: 0,display: { xs: "flex", md: "flex" },
              }}
            >
              <IconButton style={{ color: "black" }} size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                style={{
                  backdropFilter: "blur(2px)",
                }}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "flex" },
                }}
              >
                <MenusLinks>
                  <MenuLinks href="/">
                    <Testing
                      style={{
                        padding: "15px 20px",
                        borderBottom: "1px solid #66727f",
                        color: "#b8c1ca",
                      }}
                      onClick={() => handleCloseNavMenu("/")}
                    >
                      Home
                    </Testing>
                  </MenuLinks>
                  <MenuLinks href="/#ourproject">
                    <Testing
                      style={{
                        padding: "15px 20px",

                        borderBottom: "1px solid #66727f",
                        color: "#b8c1ca",
                      }}
                      onClick={() => handleCloseNavMenu("/")}
                    >
                      Our Project
                    </Testing>
                  </MenuLinks>
                  <MenuLinks href="/#aboutus">
                    <Testing
                      style={{
                        padding: "15px 20px",
                        borderBottom: "1px solid #66727f",
                        color: "#b8c1ca",
                      }}
                      onClick={() => handleCloseNavMenu("/")}
                    >
                      About us
                    </Testing>
                  </MenuLinks>

                  <MenuLinks href="/#team">
                    <Testing
                      style={{
                        padding: "15px 20px",
                        borderBottom: "1px solid #66727f",
                        color: "#b8c1ca",
                      }}
                      onClick={() => handleCloseNavMenu("/")}
                    >
                      Our Team
                    </Testing>
                  </MenuLinks>
                  <MenuLinks href="/#contactus">
                    <Testing
                      style={{
                        padding: "15px 20px",
                        borderBottom: "1px solid #66727f",
                        color: "#b8c1ca",
                      }}
                      onClick={() => handleCloseNavMenu("/")}
                    >
                      Contact us
                    </Testing>
                  </MenuLinks>
                </MenusLinks>
              </Menu>
            </Box>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Index;
