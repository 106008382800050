import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Content, P, SectionSubHeading, SectionWrapper } from "../../../styles";

import aboutimage from "../../../images/aboutimage.png";
import mobileabout from "../../../images/mobileabout.png";
import { BASEURL } from "../../../configure";

const Mobbackground = styled.div`
  display: none;
  @media screen and (max-width: 599px) {
    justify-content: center;
    display: flex;
    text-align: center;
    max-width: 100%;
    width: 100%;
    padding: 100px 0;
    /* min-height: 50vh; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${mobileabout});
  }
`;
const Grids = styled(Grid)`
  text-align-last: center;
  @media screen and (max-width: 599px) {
    display: none;
  }
`;
const GridDesktop = styled(Grid)`
  @media screen and (max-width: 599px) {
    display: none;
  }
`;
const GridMobile = styled(Grid)`
  display: none;
  @media screen and (max-width: 599px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const Heading = styled.div`
  font-family: "PlayfairDisplay-SemiBold";
  font-size: ${(props) => (props.fs ? props.fs : "65px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */

  color: ${(props) => (props.color ? props.color : "#fff")};
  padding: ${(props) => (props.p ? props.p : "0")};
  /* margin: 0; */

  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 599px) {
    display: ${(props) => (props.display ? props.display : "")};
    text-align: ${(props) => (props.align599 ? props.align599 : "")};
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;
const Para = styled.span`
  font-family: "Poppins-SemiBold";
  color: black;
  font-size: 15px;
  @media screen and (max-width: 599px) {
    font-size: 15px;
  }
  @media screen and (max-width: 382px) {
    font-size: 12px;
  }
`;
const AboutDesktopImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 600px;
`;
const Index = () => {
  const [ getTitle, setTitle ] = useState('');
  const [ getContent, setContent ] = useState('');
  const [ getImage, setImage ] = useState('');
  
  useEffect(()=>{
      fetch(BASEURL+'pages/?slug=about-us&_embed', {
        method: 'GET',
      }).then(response => response.json())
      .then( jsonData => {
        setContent(jsonData[0]?.content?.rendered)
        setTitle(jsonData[0]?.title?.rendered)
        setImage(jsonData[0]?._embedded?.['wp:featuredmedia'][0]['source_url'])
      }) 
  },[getTitle, getContent, getImage])
  return (
    <div>
      <SectionWrapper p1230="2rem 0 0" p599="5rem 0" id="aboutus">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grids item xs={12} md={6} data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in"data-aos-delay="400"data-aos-offset="0">
              <AboutDesktopImage src={getImage} />
            </Grids>
            <GridDesktop item xs={12} md={6}>
              <SectionSubHeading display="none"color="#0A0A0A"data-aos="fade-zoom-in"data-aos-easing="ease-in"data-aos-duration="600"data-aos-delay="500"data-aos-offset="0">
                {getTitle}
              </SectionSubHeading>
              <Content dangerouslySetInnerHTML={{__html: `${getContent}`}}fs="14px"padding="0 0"color="#0A0A0A"maxWidth="70%"maxWidth1200="80%"maxWidth908="100%"data-aos="fade-up"data-aos-easing="ease-in"data-aos-offset="0"data-aos-delay="700"data-aos-duration="600"/>
            </GridDesktop>
          </Grid>
          <Mobbackground>
            <Heading data-aos="fade-zoom-in" data-aos-easing="ease-in" data-aos-duration="600" data-aos-delay="500" data-aos-offset="0">
              About Us
            </Heading>
          </Mobbackground>
          <GridMobile container>
            <GridMobile xs={12} data-aos="fade-zoom-in" data-aos-easing="ease-in" data-aos-duration="600" data-aos-delay="500" data-aos-offset="0">
              <Content dangerouslySetInnerHTML={{__html: `${getContent}`}} color="#000"  maxWidth="70%"  fs380="12px"  fs="15px"/>
            </GridMobile>
          </GridMobile>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Index;
