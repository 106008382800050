import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import React from "react";
import { Content, P, SectionSmallHeading, SectionTitleHeading } from "../styles";
import spotify from '../images/spotify.png'
import applemusic from '../images/applemusic.png'
const MusicLinks = styled.a`
  padding-left: 10px;
  cursor: pointer;
`;
const PlayMusic = styled.div`
  display: flex;
  align-items: self-start;
`;
const GridImage = styled(Grid)`
  /* text-align-last: center; */
`;
const SliderCardImage = styled.img`
width: 100%;
height: auto;
max-width: 227px;
`;

const SliderCard = (props) => {
  return (
    <div style={{ textAlign: "-webkit-left", paddingTop: 10 }}>
      <Grid container items justifyContent="center" alignContent="center" xs={10} sm={9} md={10} lg={9} xl={8} >
        <GridImage item xs={12} md={3}>
          <SliderCardImage
            src={props.image}
          />
        </GridImage>
        <Grid item xs={12} md={9}>
          <div>
            <SectionTitleHeading padding="0">{props.title}</SectionTitleHeading>
            <Content dangerouslySetInnerHTML={{__html: `${props.tagLine}`}} m="30px 0"color="#F5F5EF"padding="0"/>
            {props.applemusiclink != '#' || props.spotifylink != '#' ?
            <PlayMusic>
              <P padding="0" m="0" color="#F5F5EF">
                Watch On:
              </P>
              {props.spotifylink != '#' ?
              <MusicLinks href={props.spotifylink} target="_blank">
                <img src={spotify} width="28" height="28" layout="intrinsic" />
              </MusicLinks>
              : null }
              {props.applemusiclink != '#' ?
              <MusicLinks href={props.applemusiclink} target="_blank">
                <img src={applemusic} width="28" height="28" layout="intrinsic" />
              </MusicLinks>
              : null }
            </PlayMusic>
            : null }
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SliderCard;
