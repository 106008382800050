import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";

import React from "react";
import { P, SectionFooterHeading, SectionWrapper } from "../../../styles";

import logodark from "../../../images/logodark.png";
import TikTokB from "../../../images/TikTokB.png";
import InstagramB from "../../../images/InstagramB.png";
import TwitterB from "../../../images/TwitterB.png";
import FacebookB from "../../../images/FacebookB.png";
import imdbmob from "../../../images/imdbmob.png";

const SocialsDiv = styled.div`
  display: grid;
  padding: 30px 0 20px 0;
`;
const SocialLinks = styled.a`
  color: #0a0a0a;
  font-family: "Poppins-SemiBold";
  text-decoration: none;
`;
const GridBorder = styled(Grid)`
  border-bottom: 1px solid #0a0a0a;
  border-top: 1px solid #0a0a0a;
  align-items: CENTER;
  padding: 10px 0;
  @media screen and (max-width: 599px) {
    border-bottom: none;
    text-align-last: center;
  }
`;
const GridDesktop = styled(Grid)`
  @media screen and (max-width: 599px) {
    display: none;
  }
`;
const SocialMedia = styled.div`
  display: none;

  @media screen and (max-width: 599px) {
    display: flex;
    text-align: center;
    text-align-last: center;
    justify-content: center;
  }
`;
const SocialLinksMob = styled.a`
  place-content: center;
  padding: 0 10px;
`;
const DarkLogo = styled.img`
  width: 100%;
  height: auto;
  max-width: 210px;
`;
const SocialMediaMob = styled.img`
  width: 100%;
  height: auto;
  max-width: 22px;
`;

const index = () => {
  return (
    <div>
      <SectionWrapper p599="1px 0" p="1px 0 ">
        <Container maxWidth="xl">
          <GridBorder container>
            <Grid
              xs={12}
              md={5}
              sm={6}
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="400"
              data-aos-easing="ease-in"
              data-aos-offset="0"
            >
              <DarkLogo src={logodark} />
              {/* <P maxWidth="70%" align599="center">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.{" "}
              </P> */}
            </Grid>
            <Grid
              xs={12}
              md={5}
              sm={4}
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="500"
              data-aos-easing="ease-in"
              data-aos-offset="0"
            >
              {/* <SectionFooterHeading>Quick links</SectionFooterHeading> */}
              <SocialsDiv>
                <SocialLinks href="">Home</SocialLinks>
                <SocialLinks href="/#ourproject">Our Projects</SocialLinks>
                <SocialLinks href="/#aboutus">About us</SocialLinks>
                <SocialLinks href="/#team">Team</SocialLinks>
                <SocialLinks href="/#contactus">Contact us</SocialLinks>
              </SocialsDiv>
            </Grid>
            <GridDesktop
              xs={12}
              md={2}
              sm={2}
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay="600"
              data-aos-easing="ease-in"
              data-aos-offset="0"
            >
              {/* <SectionFooterHeading>Socials</SectionFooterHeading> */}
              <SocialsDiv>
                <SocialLinks href="">TikTok</SocialLinks>
                <SocialLinks href="">Instagram</SocialLinks>
                <SocialLinks href="">Twitter</SocialLinks>
                <SocialLinks href="">Facebook</SocialLinks>
                <SocialLinks href="">IMDb</SocialLinks>
              </SocialsDiv>
            </GridDesktop>
          </GridBorder>
          <SocialMedia
            data-aos="fade-up"
            data-aos-duration="400"
            data-aos-delay="400"
            data-aos-easing="ease-in"
            data-aos-offset="0"
          >
            <SocialLinksMob href="#" target="_blank">
              <SocialMediaMob src={TikTokB} />
            </SocialLinksMob>
            <SocialLinksMob href="#" target="_blank">
              <SocialMediaMob src={InstagramB} />
            </SocialLinksMob>
            <SocialLinksMob href="#" target="_blank">
              <SocialMediaMob src={TwitterB} />
            </SocialLinksMob>
            <SocialLinksMob href="#" target="_blank">
              <SocialMediaMob src={FacebookB} />
            </SocialLinksMob>
            <SocialLinksMob href="#" target="_blank">
              <SocialMediaMob src={imdbmob} />
            </SocialLinksMob>
          </SocialMedia>
          <P tal="center" padding="10px  0 0 0" fs599="14px" align="center">
            Copyright © 2022 Primary Source Media
          </P>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;
