import './App.css';

import MainPage from "../src/pages/MainPage"

function App() {
  return (
    <div className="App">
      <MainPage/>
    </div>
  );
}

export default App;
