import styled from "@emotion/styled";

const SectionWrapper = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#F5F5EF")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  @media screen and (max-width: 1230px) {
    padding: ${(props) => (props.p1230 ? props.p1230 : "")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "")};
  }
`;
const P = styled.p`
  text-align: ${(props) => (props.tlj ? props.tlj : "justify !important")};
  WORD-SPACING: -1px;
  line-height: ${(props) => (props.lh ? props.lh : "")};
  font-family: "Poppins-Regular";
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  color: ${(props) => (props.color ? props.color : "#0A0A0A")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  text-align: ${(props) => (props.aligmoz ? props.aligmoz : "")};
  text-align-last: ${(props) => (props.tal ? props.tal : "")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  padding: ${(props) => (props.padding ? props.padding : "20px 0")};
  
  @media (max-width: 1420px) {
    max-width: ${(props) => (props.maxWidth1420 ? props.maxWidth1420 : "")};
  }
  @media (max-width: 1330px) {
    font-size: ${(props) => (props.fs1330 ? props.fs1330 : "")};
    max-width: ${(props) => (props.maxWidth1200 ? props.maxWidth1200 : "")};
  }
  @media (max-width: 1270px) {
    max-width: ${(props) => (props.maxWidth1250 ? props.maxWidth1250 : "")};
  }
  @media (max-width: 908px) {
    font-size: ${(props) => (props.fs900 ? props.fs900 : "")};
    line-height: ${(props) => (props.lh900 ? props.lh900 : "")};
    max-width: ${(props) => (props.maxWidth908 ? props.maxWidth908 : "")};
  }
  @media (max-width: 800px) {
    font-size: ${(props) => (props.fs800 ? props.fs800 : "")};
    line-height: ${(props) => (props.lh800 ? props.lh800 : "")};
  }
  @media (max-width: 599px) {
  // text-align: unset !important;
    text-align: ${(props) => (props.align599 ? props.align599 : "")};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "15px")};
    line-height: ${(props) => (props.lh599 ? props.lh599 : "")};
    max-width: 100%;
  }
  @media (max-width: 420px) {
    font-size: ${(props) => (props.fs404 ? props.fs404 : "")};
  }
  @media (max-width: 382px) {
    font-size: ${(props) => (props.fs380 ? props.fs380 : "")};
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
`;
const CustomContainer = styled.div`
  max-width: 2600px;
`;
const SectionHeading = styled.h2`
  color: #fff;
  font-family: "PlayfairDisplay-SemiBold";
  font-size: 120px;
  line-height: 120px;
  letter-spacing: 10px;
  margin-top: ${(props) => (props.m ? props.m : "")};
  @media (max-width: 899px) {
    font-size: 80px;
    line-height: unset;
    letter-spacing: unset;
  }
  @media (max-width: 599px) {
    text-align: right;
    padding: ${(props) => (props.P599 ? props.p599 : "70px 0")};
    line-height: unset;
    font-size: 60px;
  }
`;
const SectionSubHeading = styled.h3`
  font-family: "PlayfairDisplay-SemiBold";
  font-size: ${(props) => (props.fs ? props.fs : "65px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */

  color: ${(props) => (props.color ? props.color : "#F5F5EF")};
  padding: ${(props) => (props.p ? props.p : "0")};
  /* margin: 0; */

  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 1270px) {
    padding: ${(props) => (props.p1270 ? props.p1270 : "")};
    font-size: ${(props) => (props.fs1270 ? props.fs1270 : "")};
  }
  @media (max-width: 1014px) {
    font-size: ${(props) => (props.fs1014 ? props.fs1014 : "")};
  }
  @media (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "")};
    display: ${(props) => (props.display ? props.display : "")};
    text-align: ${(props) => (props.align599 ? props.align599 : "")};
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;
const SectionSmallHeading = styled.h3`
  font-family: "Poppins-SemiBold";
  font-size: ${(props) => (props.fs ? props.fs : "25px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */

  color: ${(props) => (props.color ? props.color : "#fff")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: 0;
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 599px) {
    font-family: "Poppins-Regular";
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;
const SectionTitleHeading = styled.h3`
  font-family: "Poppins-Bold";
  font-size: ${(props) => (props.fs ? props.fs : "25px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */

  color: ${(props) => (props.color ? props.color : "#F5F5EF")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};

  @media (max-width: 599px) {
    font-family: "Poppins-SemiBold";
    padding: ${(props) => (props.p599 ? props.p599 : "0")};
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;
const SectionFooterHeading = styled.h3`
  font-family: "PlayfairDisplay-Bold";
  font-size: ${(props) => (props.fs ? props.fs : "30px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */

  color: ${(props) => (props.color ? props.color : "#0A0A0A")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};

  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;
const Content = styled.div`
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  
  padding: ${(props) => (props.padding ? props.padding : "20px 0")};
  *{
    text-align: ${(props) => (props.tlj ? props.tlj : "justify !important")};
    WORD-SPACING: -1px;
    line-height: ${(props) => (props.lh ? props.lh : "")};
    font-family: "Poppins-Regular";
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
    font-size: ${(props) => (props.fs ? props.fs : "18px")};
    color: ${(props) => (props.color ? props.color : "#0A0A0A")};
    text-align: ${(props) => (props.align ? props.align : "left")};
    text-align: ${(props) => (props.aligmoz ? props.aligmoz : "")};
    text-align-last: ${(props) => (props.tal ? props.tal : "")};
    
    @media (max-width: 1420px) {
      max-width: ${(props) => (props.maxWidth1420 ? props.maxWidth1420 : "")};
    }
    @media (max-width: 1330px) {
      font-size: ${(props) => (props.fs1330 ? props.fs1330 : "")};
      max-width: ${(props) => (props.maxWidth1200 ? props.maxWidth1200 : "")};
    }
    @media (max-width: 1270px) {
      max-width: ${(props) => (props.maxWidth1250 ? props.maxWidth1250 : "")};
    }
    @media (max-width: 908px) {
      font-size: ${(props) => (props.fs900 ? props.fs900 : "")};
      line-height: ${(props) => (props.lh900 ? props.lh900 : "")};
      max-width: ${(props) => (props.maxWidth908 ? props.maxWidth908 : "")};
    }
    @media (max-width: 800px) {
      font-size: ${(props) => (props.fs800 ? props.fs800 : "")};
      line-height: ${(props) => (props.lh800 ? props.lh800 : "")};
    }
    @media (max-width: 599px) {
    // text-align: unset !important;
      text-align: ${(props) => (props.align599 ? props.align599 : "")};
      font-size: ${(props) => (props.fs599 ? props.fs599 : "15px")};
      line-height: ${(props) => (props.lh599 ? props.lh599 : "")};
      max-width: 100%;
    }
    @media (max-width: 420px) {
      font-size: ${(props) => (props.fs404 ? props.fs404 : "")};
    }
    @media (max-width: 382px) {
      font-size: ${(props) => (props.fs380 ? props.fs380 : "")};
    }
    @media (max-width: 1440px) and (max-height: 620px) {
      padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
    }
  }

`;
export {
  Content,
  SectionWrapper,
  P,
  SectionHeading,
  SectionSubHeading,
  CustomContainer,
  SectionSmallHeading,
  SectionTitleHeading,
  SectionFooterHeading,
};
