import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SectionSubHeading } from "../../../styles";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import Slider from "react-slick";
import SliderCard from "../../../components/SliderCards";

import Avatar from "../../../images/avatar.png";
import tearup from "../../../images/tearup.png";
import teardown from "../../../images/teardown.png";
import projectbg from "../../../images/projectbg.png";
import { BASEURL } from "../../../configure";


const SectionWrapper = styled.div`
  min-height: 100vh;
  padding: 65px 0 150px 0;
  display: flex;
  align-items: center;
  background: url(${tearup}), url(${teardown}),
    url(${projectbg});
  background-position: top, bottom, center;
  background-repeat: no-repeat;
  background-size: contain, contain, cover;
  @media screen and (max-width: 899px) {
    padding: 50px 0;
    background-size: contain, contain, auto;
  }
  @media screen and (max-width: 599px) {
    background-size: contain, contain, auto;
    padding: 20px 0;
  }
`;
const SliderWrapper = styled.div`
  /* overflow: auto;
  white-space: nowrap; */
  /* height: calc(100vh - 557px); */
  padding: 15px;
  height: 385px;
  overflow: auto;
  /* overflow-x: auto;
  white-space: nowrap; */
  /* max-width: 2500px; */
  /* width */
  &::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #b32929;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media screen and (max-width: 599px) {
    height: 485px;
  }
`;
const Index = () => {
  const sliders = useRef(null);
  const [ getTeamData, setTeamData ] = useState('')
  function scroll(e) {
    if (sliders === null) return 0;

    e.wheelDelta > 0
      ? sliders.current.slickNext()
      : sliders.current.slickPrev();
  }
  const settings = {
    slidesToShow: 4,
    swipeToSlide: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let getTeam = async () => {
    let team = await fetch(BASEURL+'team?_embed', {
      method: 'GET',
    })
    const data = await team.json();
    
    setTeamData(data.map((key, index) => {
      return  (
        <SliderCard
          key={index}
          image={key?._embedded ? key?._embedded?.['wp:featuredmedia'][0]['source_url'] : Avatar}
          title={key['title']['rendered']}
          tagLine={key['metadata'].designation[0]}
        />
      )
    }))
  }
  useEffect(() => {

    getTeam();
    
    // console.log('team: ', getTeam)
    window.addEventListener("wheel", scroll, true);

    return () => {
      window.removeEventListener("wheel", scroll, true);
    };
  }, []);
  return (
    <div>
      <SectionWrapper id="team">
        <Container maxWidth="xl">
          <SectionSubHeading
            fs599="55px"
            align="center"
            align599="center"
              
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-duration="600"
            data-aos-delay="600"
            data-aos-offset="0"
          >
            Our Team
          </SectionSubHeading>

          <SliderWrapper>
            <Grid container justifyContent="center" alignContent="center">
              <Grid xs={12}>
                <div
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-duration="900"
                  data-aos-delay="900"
                  data-aos-offset="0"
                >
                  <Slider {...settings} ref={sliders}>
                    {getTeamData}
                  </Slider>
                </div>
              </Grid>
            </Grid>
          </SliderWrapper>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Index;
