import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React from "react";
import { P } from "../../../styles";

const Wrapper = styled.div`
  background-color: #f5f5ef;
  /* min-height: 100vh; */
  padding: 70px 0;
  display: flex;
  align-items: center;
  text-align: center;
  place-content: center;
  justify-content: center;
`;
const SecionPrimaryPara = styled.div`
  color: #212121;

  text-align: left;
  font-size: 64px;
  font-family: "PlayfairDisplay-SemiBold";
  @media screen and (max-width: 800px) {
    font-size: 44px;
  }
  @media screen and (max-width: 520px) {
    font-size: 35px;
  }
  @media screen and (max-width: 465px) {
    font-size: 30px;
  }
  @media screen and (max-width: 380px) {
    font-size: 24px;
  }
`;
const SecionPrimaryParaItalic = styled.span`
  color: #212121;
  font-size: 26px;
  font-family: "Poppins-SemiBoldItalic";
  padding-left: 10px;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
  @media screen and (max-width: 580px) {
    font-size: 15px;
  }
  @media screen and (max-width: 465px) {
    font-size: 12px;
  }
  @media screen and (max-width: 380px) {
    padding-left: 2px;
    font-size: 10px;
  }
`;
const Slidedown = styled.a`
  text-decoration: none;
`;
const ParaDiv = styled.div`
  justify-content: center;
  display: flex;
  text-align: " -webkit-center";
  text-align: "-moz-center";
`;
const index = () => {
  return (
    <div>
      <Slidedown href="/#ourproject">
        <Wrapper id="primary">
          <Container
            maxWidth="xl"
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-easing="ease-in"
            data-aos-delay="0"
            data-aos-offset="0"
          >
            <SecionPrimaryPara>
              Primary Source:
              <SecionPrimaryParaItalic>
                n.[prí - merē |sôrs|]
              </SecionPrimaryParaItalic>
            </SecionPrimaryPara>
            <ParaDiv>
              <P
              align="center"
              maxWidth="55%"
              fs="44px"
              fs900="33px"
              fs800="25px"
              lh800="30PX"
              lh="55px"
              fs1330="38px"
              maxWidth1200="70%"
              fs404="22px"
              fs599="24px"
                lh599="27PX"
                fs380="18px"
              >
                Primary sources are immediate, first-hand accounts of topics and
                events, from people who had a direct connection with it.
              </P>
            </ParaDiv>
          </Container>
        </Wrapper>
      </Slidedown>
    </div>
  );
};

export default index;
