import React from "react";


import styled from "@emotion/styled";
import { Container } from "@mui/material";

import MenuPSM from "./MenuPSM";

import HeaderImage from "../../../images/bg.png";
import teardownforHeader from "../../../images/teardownforHeader.png";
import HeaderMobImage from "../../../images/bgmobile.png";
import logowhite from "../../../images/logowhite.png";
import Tiktok from "../../../images/TikTok.png";
import Instagram from "../../../images/instagram.png";
import Twitter from "../../../images/twitter.png";
import Facebook from "../../../images/fb.png";
import Imdb from "../../../images/imdb.png";

const TearDownRelative = styled.div`
  position: relative;
`;
const TearDown = styled.div`
  width: 100%;
  position: absolute;
  bottom: -15px;
  // z-index: 1;
  @media screen and (max-width: 899px) {
    // display: none;
  }
`;
const MainWrapper = styled.div`
  background-image: url(${HeaderImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 599px) {
    background-image: url(${HeaderMobImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;
const Wrapper = styled.div`
  min-height: 92vh;
  position: relative;
  display: flex;
  place-items: center;
  @media screen and (min-width: 1920px) and (min-height: 1200px) {
    min-height: 94vh;
  }
`;
const SocialMedia = styled.div`
  z-index: 2;
  display: inline-grid;
  position: absolute;
  right: 50px;
  top: 100px;
  @media screen and (max-width: 599px) {
    right: 20px;
  }
`;
const SocialMediaLinks = styled.a`
  z-index: 2;
  mix-blend-mode: lighten;
  padding-top: 15px;
  opacity: 0.7;
`;
const Downarrow = styled.a`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 50px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 599px) {
    display: none;
  }
`;
const SectionHeading = styled.div`
  position: absolute;
  left: 28%;
  top: 50px;
  bottom: 0;
  display: flex;
  align-items: center;
  place-content: center;
  z-index: 1;
  @media screen and (min-width: 1840px) {
    right: 0;
    left: 15%;
    max-width: 70%;
  }
  @media screen and (max-width: 1440px) {
    right: 0;
    left: 15%;
    max-width: 70%;
  }
  @media screen and (max-width: 899px) {
    right: 0;
    left: 15%;
    max-width: 70%;
  }
  @media screen and (max-width: 599px) {
    left: 15%;
    top: unset;
    bottom: 0px;
  }
`;

const Slidedown = styled.a``;
const MainLogo = styled.img`
  width: 100%;
  height: auto;
  max-width: 700px;
`;
const SocialMediaImages = styled.img`
  width: 100%;
  height: auto;
  max-width: 32px;
`;
const Index = () => {
  return (
    <div>
      <Slidedown href="/#primary">
        <SectionHeading>
          <MainLogo src={logowhite} />
        </SectionHeading>
      </Slidedown>
      <div
        data-aos="fade-in"
        data-aos-duration="1600"
        data-aos-easing="ease-in-out"
        data-aos-delay="1600"
        data-aos-offset="0"
      >
        <MainWrapper>
          <MenuPSM />
          <Wrapper>
            {/* <Container maxWidth="xl">
              <Downarrow href="/#primary">
                <img
                  src="/images/downarrow.png"
                  width="50"
                  height="28"
                  layout="intrinsic"
                />
              </Downarrow>
            </Container> */}
          </Wrapper>
        </MainWrapper>
        <SocialMedia>
          {" "}
          <SocialMediaLinks href="#" target="_blank">
            <SocialMediaImages src={Tiktok} />
          </SocialMediaLinks>{" "}
          <SocialMediaLinks href="#" target="_blank">
            <SocialMediaImages src={Instagram} />
          </SocialMediaLinks>
          <SocialMediaLinks href="# " target="_blank">
            <SocialMediaImages src={Twitter} />
          </SocialMediaLinks>{" "}
          <SocialMediaLinks href="#" target="_blank">
            <SocialMediaImages src={Facebook} />
          </SocialMediaLinks>
          <SocialMediaLinks href="# " target="_blank">
            <SocialMediaImages src={Imdb} />
          </SocialMediaLinks>
        </SocialMedia>
        <TearDownRelative>
          <TearDown>
            <img
              src={teardownforHeader}
              style={{ width: "100%", height: "auto" }}
              layout="intrinsic"
            />
          </TearDown>
        </TearDownRelative>
      </div>
    </div>
  );
};

export default Index;
