import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React, { useState,useEffect } from "react";
import { Content, P, SectionSubHeading, SectionWrapper } from "../../../styles";

import matters from "../../../images/matters.png";
import patricsign from "../../../images/patricsign.png";
import { BASEURL } from "../../../configure";

const QouteBackgroundImage = styled.div`
  /* margin: 0 100px; */
  max-width: 61%;
  background-image: url(${props => props.bk});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 150px 50px 40px 50px;

  @media screen and (max-width: 1370px) {
    max-width: 70%;
    min-height: 70%;
  }
  @media screen and (max-width: 1170px) {
    min-height: 50%;
    padding: 150px 50px 100px;
  }
  @media screen and (max-width: 1025px) {
    max-width: 100%;
    padding: 150px 30px 100px;
  }
  @media screen and (max-width: 899px) {
    padding: 150px 30px 50px;
  }
  @media screen and (max-width: 599px) {
    padding: 120px 30px 50px;
  }
  @media screen and (max-width: 359px) {
    padding: 80px 8px 25px;
  }
`;
const SignImages = styled.div`
  text-align: right;
  @media screen and (max-width: 599px) {
  }
`;
const SignHeading = styled.div`
  text-align: right;
  color: #f5f5ef;
  font-family: "PlayfairDisplay-SemiBold";
  font-size: 18px;
  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
`;
const Italic = styled.span`
  font-family: "Poppins-SemiBoldItalic";
  font-size: 18px;
  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
`;
const QoutePara = styled.div`
  font-family: "Poppins-Regular";
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  color: ${(props) => (props.color ? props.color : "#000")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "center")};
  text-align-last: center;
  margin: ${(props) => (props.m ? props.m : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  @media screen and (max-width: 599px) {
    font-size: 28px;
  }
`;
const Grids = styled(Grid)`
  text-align-last: right;
  @media screen and (max-width: 599px) {
    /* display: none; */
  }
`;
const GridDesktop = styled(Grid)`
  text-align: -webkit-center !important;
  text-align: -moz-center;
  @media screen and (max-width: 1270px) {
    text-align: unset !important;
    text-align: unset !important;
  }
`;
const Heading = styled.div`
  font-family: "PlayfairDisplay-SemiBold";
  font-size: ${(props) => (props.fs ? props.fs : "65px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */

  color: ${(props) => (props.color ? props.color : "#fff")};
  padding: ${(props) => (props.p ? props.p : "0")};
  /* margin: 0; */

  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 599px) {
    display: ${(props) => (props.display ? props.display : "")};
    text-align: ${(props) => (props.align599 ? props.align599 : "")};
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;

const SignImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 240px;
`;
const Index = () => {
  const [ getTitle, setTitle ] = useState('');
  const [ getContent, setContent ] = useState('');
  const [ getImage, setImage ] = useState('');

  useEffect(()=>{
    fetch(BASEURL+'pages/?slug=why-this-matters&_embed', {
      method: 'GET',
    }).then(response => response.json())
    .then( jsonData => {
      setContent(jsonData[0]?.content?.rendered)
      setTitle(jsonData[0]?.title?.rendered)
      setImage(jsonData[0]?._embedded?.['wp:featuredmedia'][0]['source_url'])
    }) 
},[getTitle, getContent, getImage])
  return (
    <div>
      <SectionWrapper p599="1rem 0 4rem 0" m="1px 0" p="4rem 0 10rem 0" p1230="4rem 0 10rem 0" id="aboutus">
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <GridDesktop item xs={12} md={6}>
              <SectionSubHeading fs="75px" fs1270="59px" fs1014="51px" fs599="42px" color="#0A0A0A" padding="0" data-aos="fade-zoom-in" data-aos-easing="ease-in" data-aos-duration="600" data-aos-delay="500" data-aos-offset="0">
                {getTitle}
              </SectionSubHeading>

              <Content dangerouslySetInnerHTML={{__html: `${getContent}`}}fs380="12px"fs599="15px"fs="15px"maxWidth="80%"maxWidth1420="90%"maxWidth1250="100%"m="0"padding="0"data-aos="fade-up"data-aos-easing="ease-in"data-aos-duration="700"data-aos-delay="700"data-aos-offset="0"/>
            </GridDesktop>
            <Grids item xs={12} md={6} data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in" data-aos-delay="400" data-aos-offset="0" >
              <QouteBackgroundImage bk={getImage} data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in" data-aos-delay="400" data-aos-offset="0" >
                <QoutePara padding="0" m="0" fs="32px" fs599="25px" color="#F5F5EF" align="center" data-aos="fade-zoom-in" data-aos-easing="ease-in" data-aos-duration="700" data-aos-delay="700" data-aos-offset="0" >
                  “I know of no way of judging of the future but by the past.”
                </QoutePara>

                <SignImages data-aos="fade-zoom-in" data-aos-easing="ease-in" data-aos-duration="800" data-aos-delay="800" data-aos-offset="0" >
                  <SignImage src={patricsign} />
                  <SignHeading>
                    - Patrick Henry,<Italic> 1775</Italic>
                  </SignHeading>
                </SignImages>
              </QouteBackgroundImage>
            </Grids>
          </Grid>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Index;
