import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import React from "react";
import { P, SectionSmallHeading, SectionTitleHeading } from "../styles";

const OurImg = styled.img`
  width: 100%;
  height: auto;
  @media screen and (max-width:599px){
    max-width: 380px;
  }
`;

const GridForImage = styled(Grid)`
  box-shadow: ${(props) => (props.bs ? props.bs : "")};
`;

const SliderCard = (props) => {
  return (
    <div style={{ textAlign: "-webkit-center", paddingTop: 60 }}>
      <Grid container items justifyContent="center" alignContent="center" xs={10} sm={9} md={10} lg={9} xl={8} >
        <GridForImage bs="0px 0px 12px 1px rgb(0 0 0 / 50%)" item xs={12}>
          <OurImg src={props.image} />
        </GridForImage>
        <Grid item xs={12}>
          <div>
            <SectionTitleHeading align="center" padding="0">
              {props.title}
            </SectionTitleHeading>
            <P align="center" tlj="unset" m="0" color="#fff" padding="0">
              {props.tagLine}
            </P>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SliderCard;
