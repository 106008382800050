import styled from "@emotion/styled";
import React, { memo, useEffect } from "react";
import S1_Header from "../MainPage/S1_Header";
import S2_Primary from "../MainPage/S2_Primary";
import S3_OurProject from "../MainPage/S3_OurProject";
import S4_Aboutus from "../MainPage/S4_Aboutus";
import S5_Matters from "../MainPage/S5_Matters";
import S6_OurTeam from "../MainPage/S6_OurTeam";
import S7_Contact from "../MainPage/S7_Contact";
import S8_Footer from "../MainPage/S8_Footer";
import Aos from "aos";
import "aos/dist/aos.css";
const Wrapper = styled.div`
  background-color: #f5f5ef;
`;
const MainPage = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("load", function () {
      Aos.init();
    });
  }, []);
  return (
    <div>
      <S1_Header />
      <Wrapper>
        <S2_Primary />
        <S3_OurProject />
        <S4_Aboutus />
        <S5_Matters />
        <S6_OurTeam />
        <S7_Contact />
        <S8_Footer />
      </Wrapper>
    </div>
  );
};

export default memo(MainPage);
