import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {
  P,
  SectionSmallHeading,
  SectionSubHeading,
  SectionTitleHeading,
  SectionWrapper,
} from "../../../styles";

const Contact = styled.a``;

const index = () => {
  return (
    <div>
      <SectionWrapper p="0rem 0" m="-1px 0" id="contactus">
        <Container maxWidth="xl">
          <SectionSubHeading
            fs599="50px"
            p="50px 0"
            color="#0A0A0A"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-duration="600"
            data-aos-delay="600"
            data-aos-offset="0"
          >
            Contact Us
          </SectionSubHeading>
          <Grid container>
            <Grid xs={12} md={8}>
              <SectionTitleHeading
                fs="30px"
                color="#0A0A0A"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-easing="ease-in"
                data-aos-delay="400"
                data-aos-offset="0"
              >
                Production
              </SectionTitleHeading>
              <P
              tlj="unset"
                m="0"
                padding="10px 0 0px 0"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="500"
                data-aos-easing="ease-in"
                data-aos-offset="0"
              >
                For all inquiries regarding production and <br />
                projects,
              </P>
              <P
                m="0"
                padding="0px 0 10px 0"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="500"
                data-aos-easing="ease-in"
                data-aos-offset="0"
              >
                {" "}
                please contact:
              </P>
              <SectionSmallHeading
                color="#0A0A0A"
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-easing="ease-in"
                data-aos-delay="600"
                data-aos-offset="0"
              >
                Bella King
              </SectionSmallHeading>
              <Contact
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-easing="ease-in"
                data-aos-delay="600"
                data-aos-offset="0"
                style={{ color: "#000", textDecoration: "none" }}
                href="tel: 
                310.975.9984"
              >
                310.975.9984
              </Contact>
              <br />{" "}
              <Contact
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-easing="ease-in"
                data-aos-delay="700"
                data-aos-offset="0"
                style={{ color: "#000", textDecoration: "none" }}
                href="mailto:
                hello@primarysourcemedia.com"
              >
                hello@primarysourcemedia.com
              </Contact>
            </Grid>
            <Grid xs={12} md={4}>
              <SectionTitleHeading
                p599="60px 0 0 0"
                fs="30px"
                color="#0A0A0A"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-easing="ease-in"
                data-aos-delay="400"
                data-aos-offset="0"
              >
                Media Inquiries & Partnership
              </SectionTitleHeading>
              <P 
              tlj="unset"
                m="0"
                padding="10px 0 0 0"
                data-aos="fade-up"
                data-aos-easing="ease-in"
                data-aos-duration="500"
                data-aos-delay="500"
                data-aos-offset="0"
              >
                For all inquiries regarding marketing and public relations,
              </P>
              <P
                m="0"
                padding="0px 0 10px 0"
                data-aos="fade-up"
                data-aos-easing="ease-in"
                data-aos-duration="500"
                data-aos-delay="500"
                data-aos-offset="0"
              >
                please contact:
              </P>
              <SectionSmallHeading
                color="#0A0A0A"
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-easing="ease-in"
                data-aos-delay="600"
                data-aos-offset="0"
              >
                Corie Moscow
              </SectionSmallHeading>
              <Contact
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-easing="ease-in"
                data-aos-delay="600"
                data-aos-offset="0"
                style={{ color: "#000", textDecoration: "none" }}
                href="tel: 215.518.5526"
              >
                215.518.5526
              </Contact>
              <br />
              <Contact
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-easing="ease-in"
                data-aos-delay="700"
                data-aos-offset="0"
                style={{ color: "#000", textDecoration: "none" }}
                href="mailto: coriemoskow@glosspr.com"
              >
                coriemoskow@glosspr.com
              </Contact>
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;
